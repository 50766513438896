function Contact() {
    return (
        <div className="col-span-1 md:ml-8">
            <p className="text-4xl md:text-6xl mb-8">Contact</p>
            <p className="text-gold font-bold text-2xl mb-2">LOCATION</p>
            <p>10 Richmond Mews, London W1D 3DD</p>
            <p className="text-gold font-bold text-2xl mb-2 mt-6">EMAIL</p>
            <button onClick={() => window.location = 'mailto:info@linton-capital.co.uk'}>info@linton-capital.co.uk</button>
        </div>
    );
  }
  
  export default Contact;

  
  