import { NavLink } from "react-router-dom";
import Contact from "./contact"

function HomeBody() {
    return (
        <div className="container mx-auto max-w-6xl mb-20">
            <div className="grid md:grid-cols-3 gap-8 py-10 px-6 md:px-0">
                <div className="col-span-2">
                    <h2 className="text-4xl md:text-6xl App--deskLh">Experienced and patient investment for growth</h2>
                    <p className="mt-6">Linton Capital is a London based investor and fund management business which has been in operation since 2005.  Linton has made investments as principal and managed companies across the UK, Europe, Africa, the Middle East and North America, as well as advising on and procuring capital in connection with acquisitions.  Linton has deep experience in the Energy, Technology and Media sectors, and has made investments in both public and private companies.</p>
                    <p className="mt-6">We are always available to discuss the needs of companies, whether capital or advice.</p>
                </div>
                <Contact />
            </div>
            <NavLink className="bg-luna hover:bg-gold text-white transition-all py-4 px-8 rounded text-2xl ml-6 md:ml-0" to="/about">About</NavLink>
        </div>
    );
  }
  
  export default HomeBody;

  
  