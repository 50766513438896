import logo from './logo.svg';
import './App.css';
import { NavLink, Link } from "react-router-dom";

function navbar() {

  const navLinkStyles = ({isActive}) => {
    return{
      color: isActive ? '#fdb212' : 'black',
    }
  }

  return (
    <div className="container mx-auto max-w-6xl">
      <header className="App-header grid md:grid-cols-2 py-4">
        <Link to="/" alt="homepage">
          <img src={logo} className="App-logo mx-auto md:mx-0 mb-6 md:mb-0" alt="logo" />
        </Link>
        <div className="flex items-center justify-center md:justify-end">
          <NavLink style={navLinkStyles} className="text-2xl mx-2" to="/">Home</NavLink>
          <NavLink style={navLinkStyles} className="text-2xl mx-2" to="/about">About</NavLink>
        </div>
      </header>
    </div>
  );
}

export default navbar;
