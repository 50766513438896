import background from './background.svg';
import AboutBody from './aboutBody'

function About() {
    return (
      <div>
        <div className="App--background text-center py-20 md:py-60 px-6 text-white" style={{ backgroundImage: `url(${background})` }}> 
          <p className="md:text-2xl text-gold">ABOUT</p>
          <h1 className="App--homeh1 App--deskLh text-4xl md:text-6xl mt-6">Creative Capital Investing</h1>
        </div>
        <div>
          <AboutBody />
        </div>
      </div>
    );
  }
  
  export default About;

  
  