import background from './background.svg';
import HomeBody from './homeBody'

function Home() {
    return (
      <div>
        <div className="App--background text-center px-6 py-20 md:py-60 text-white" style={{ backgroundImage: `url(${background})` }}> 
          <p className="md:text-2xl text-gold">LINTON CAPITAL LLP</p>
          <h1 className="App--homeh1 App--deskLh text-4xl md:text-6xl mt-6">Boutique Investor + <br></br>Fund Management</h1>
        </div>
        <div>
          <HomeBody />
        </div>
      </div>
    );
  }
  
  export default Home;

  
  